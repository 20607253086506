<template>
  <v-container
    fluid
    class="text-justify"
  >
    <v-row no-gutters justify="center">
      <v-col md="8">
        <h2 class="text-h6 text-center mb-5">
          Datenschutzerkl&auml;rung f&uuml;r das DV-Verfahren VOKAR
        </h2>
        <h3 class="text-subtitle-1 font-weight-bold mb-2">Einleitung</h3>
        <p>
          Das Landesamt f&uuml;r Natur, Umwelt und Klima Nordrhein-Westfalen (LANUK)
          nimmt den Schutz Ihrer personenbezogenen Daten sehr ernst.
          Wir m&ouml;chten Ihnen mit dieser Datenschutzerkl&auml;rung daher einen &Uuml;berblick dar&uuml;ber geben,
          wie das LANUK den Schutz Ihrer Daten gew&auml;hrleistet, welche Art von Daten zu welchem
          Zweck erhoben werden und wie sie verwendet werden. Außerdem werden Sie &uuml;ber Ihre
          Rechte in Datenschutzangelegenheiten informiert und dar&uuml;ber, an wen Sie sich
          diesbez&uuml;glich im Landesamt f&uuml;r Natur, Umwelt und Klima Nordrhein-Westfalen
          wenden k&ouml;nnen, informiert.
        </p>
        <p>
          Mit diesem Informationsschreiben erhalten Sie einen &Uuml;berblick, welche personenbezogenen
          Daten erhoben und verarbeitet werden.
        </p>

        <h3 class="text-subtitle-1 font-weight-bold mb-2">Grundlagen der Datenverarbeitung</h3>
        <p>
          Die rechtliche Grundlage f&uuml;r die Datenverarbeitung auch der personenbezogenen Daten in
          VOKAR bildet das § 74 Landesnaturschutzgesetz NRW (LNatSchG) i.V.m.
          § 66 Bundesnaturschutzgesetz (BNatSchG). VOKAR bildet auch das &ouml;ffentliche Verzeichnis
          nach § 74 LNatSchG i.V.m. § 66 BNatSchG.
        </p>

        <h3 class="text-subtitle-1 font-weight-bold mb-2">Art der verarbeiteten Daten</h3>
        <h4 class="text-subtitle-1 font-weight-bold mb-2 ml-4">Personenbezogene Daten</h4>
        <p>
          Die folgende Liste enth&auml;lt die in der Anwendung VOKAR verarbeiteten personenbezogenen Daten.
          Diese k&ouml;nnen in verschiedenen Datenarten als Datenobjekte enthalten sein.
        </p>

        <span><b>Eigent&uuml;meradressen</b></span>
        <p>
          Namen und Anschriften der Eigent&uuml;mer von zur Ver&auml;ußerung anstehenden
          Grundst&uuml;cken oder Erbbaurechten, die einem Vorkaufsrecht unterliegen.
        </p>

        <span><b>Notaradressen</b></span>
        <p>
          Namen, Anschriften und Kontaktdaten sowie pers&ouml;nliche Identifikationsnummer
          (SAFE-ID der Bundesnotarkammer (BNotK)) von Notaren, die bei der Bezirksregierung
          in Bezug auf die Wahrnehmung eines Vorkaufsrechtes anfragen.
        </p>

        <span><b>Adressen Anwendungsbetreuer</b></span>
        <p>
          Telefonnummer oder elektronische Adresse der Anwendungsbetreuer.
        </p>

        <h4 class="text-subtitle-1 font-weight-bold mb-2 ml-4">Datenarten</h4>
        <p>
          Datenarten sind Gruppen von Datenobjekten, die zu einem einheitlichen
          fachlichen Zweck verarbeitet werden.
        </p>

        <span><b>Einsichtnahme Notariat</b></span>
        <p>
          Daten, die in VOKAR festgehalten werden, wenn der Notar in das Verzeichnis der
          Vorkaufsrechte Einsicht nimmt.
          <span class="text-decoration-underline">
            Enth&auml;lt als personenbezogene Daten den Namen, die Anschrift,
            die Kontaktdaten und die SAFE-ID.
          </span>
        </p>

        <span><b>Ergebnis Einsichtnahme Notariat</b></span>
        <p>
          Gem&auml;ß § 74 LNatSchG i.V.m. § 66 BNatSchG ist das Ergebnis der
          Einsichtnahme des Notars in das Verzeichnis der Vorkaufsrechte dauerhaft zu speichern.
          Ferner werden bei Anforderung der „Auskunft Notar“ diese seitens VOKAR als PDF-Dokument
          zum Download zur Verf&uuml;gung gestellt und gespeichert.
          <span class="text-decoration-underline">
            Enth&auml;lt als personenbezogene Daten die Notaradressen und SAFE-ID.
          </span>
        </p>

        <span><b>Vollzugsunterst&uuml;tzung BezReg.</b></span>
        <p>
          Von der Anwendung vorgehaltene oder vom Notar eingegebene Daten,
          die der Vollzugsunterst&uuml;tzung bei den Bezirksregierungen dienen
          (z.B. Textbausteine oder Daten zu Empfangsvollmachten der Notare).
          <span class="text-decoration-underline">
            Als personenbezogene Daten k&ouml;nnen die Namen und Eigent&uuml;meranschriften enthalten sein.
          </span>
        </p>

        <span><b>Verzeichnis der Vorkaufsrechte</b></span>
        <p>
          Ergebnis einer Verschneidung von Daten der Katasterverwaltung und der Umweltverwaltung,
          das als Verzeichnis die von Vorkaufsrechten betroffenen Grundst&uuml;cke kartographisch
          darstellt. Der Verschnitt wird ¼ j&auml;hrlich neu erstellt.
        </p>

        <span><b>E-Mail oder Kontaktformular</b></span>
        <p>
          Daten, die bei der Meldung von Fehlern und Anregungen an die Anwendungsbetreuer anfallen.
          <span class="text-decoration-underline">
            Enth&auml;lt als personenbezogene Daten den Namen, die Anschrift und die Kontaktdaten.
          </span>
        </p>

        <h3 class="text-subtitle-1 font-weight-bold mb-2">Datenhaltung</h3>
        <p>Die Anwendung VOKAR wird bei IT.NRW gehostet.</p>

        <p>
          Landesbetrieb Information und Technik Nordrhein-Westfalen (IT.NRW)<br/>
          Mauerstraße 51<br/>
          40476 D&uuml;sseldorf<br/>
          Telefon: 0211 9449-01<br/>
          Telefax: 0211 9449-8000
        </p>

        <p>
          Bei jedem Zugriff auf Inhalte der Anwendung werden dort vor&uuml;bergehend Daten,
          die m&ouml;glicherweise eine Identifizierung zulassen, in so genannten Protokoll-
          oder Log-Dateien gespeichert. Die folgenden Daten werden bei jedem Aufruf der
          Seiten gespeichert:
        </p>

        <ul class="mb-4">
          <li>Datum und Uhrzeit des Abrufs</li>
          <li>Name des aufgerufenen Internetdienstes</li>
          <li>Name der aufgerufenen Ressource</li>
          <li>Name der verwendeten Aktion</li>
          <li>Abfrage, die der Client gestellt hat</li>
          <li>&uuml;bertragene Datenmenge</li>
          <li>Meldung, ob der Abruf erfolgreich war</li>
          <li>IP-Adresse des aufrufenden Rechners</li>
          <li>Clientinformationen (Browser, Betriebssystem)</li>
        </ul>

        <p>
          Die gespeicherten Daten werden zum Zweck der statistischen Auswertung &uuml;ber
          die Nutzung dieser In-ternetpr&auml;senz erhoben, anonymisiert zusammengefasst
          und 6 Wochen vorgehalten. Weiterhin nutzen wir sie zur Abwehr beziehungsweise
          Analyse von Angriffen auf unser Webangebot
          (Rechtsgrundlage ist Art. 6 Abs. 1 S. 1 lit. e DSGVO).
        </p>

        <h3 class="text-subtitle-1 font-weight-bold mb-2">Nutzung von Cookies</h3>
        <p>
          Die Anwendung VOKAR verwendet keine Cookies, mit denen Ihr Nutzerverhalten dokumentiert
          werden k&ouml;nnte.
        </p>
        <p>
          Die Zugriffe des Benutzers einer Web-Anwendung werden vom Web-Server einer Session
          (Sitzung) zugeordnet. Diese Session bleibt im Normalfall so lange ge&ouml;ffnet,
          bis der Nutzer oder der Web-Server die Abmeldung vornimmt (z.B. Timeout).
          Um eine korrekte Datenverarbeitung sicherzustellen, ist es erforderlich
          <span class="text-decoration-underline">alle genannten Datenarten</span>
          w&auml;hrend der Dauer der Session vollst&auml;ndig vorzuhalten.
          Dies kann in der Datenbank, im Laufzeitspeicher des Web-Servers oder im
          Laufzeitspeicher des Web-Clients geschehen. Mit dem Ende der Session
          gehen alle Daten verloren, die nicht in der Datenbank gespeichert wurden.
          Zus&auml;tzlich kann die Anwendung das Ende der Session als Ausl&ouml;ser zur L&ouml;schung
          nicht mehr erforderlicher Daten in der Datenbank nutzen.
        </p>

        <p>
          Wie Sie die Verwendung von Cookies unterbinden k&ouml;nnen,
          erfahren Sie in den Datenschutzhinweisen Ihres Browsers.
        </p>

        <h3 class="text-subtitle-1 font-weight-bold mb-2">Ihre Rechte als Benutzer</h3>
        <p>
          Sie haben gegen&uuml;ber uns folgende Rechte hinsichtlich der Sie betreffenden
          personenbezogenen Daten:
        </p>

        <ul class="mb-4">
          <li>Recht auf Auskunft,</li>
          <li>Recht auf Einschr&auml;nkung der Verarbeitung,</li>
          <li>Recht auf Widerspruch gegen die Verarbeitung,</li>
          <li>Recht auf Daten&uuml;bertragbarkeit.</li>
        </ul>

        <p>
          Wenden Sie sich bitte dazu per E-Mail oder schriftlich an unsere
          <span class="font-weight-bold">Datenschutzbeauftragte:</span>
        </p>
        <p>
          Landesamt f&uuml;r Natur, Umwelt und Klima Nordrhein-Westfalen<br/>
          Datenschutzbeauftragte<br/>
          Leibnizstr. 10<br/>
          45659 Recklinghausen<br/>
          Telefon 02361 305 -0<br/>
          <a href="mailto:datenschutz@lanuk.nrw.de">Datenschutz(at)lanuk.nrw.de</a><br/>
        </p>

        <p>
          Sie haben zudem das Recht, sich bei der Landesbeauftragten f&uuml;r Datenschutz und
          Informationsfreiheit des Landes Nordrhein-Westfalen
          (<a href="https://www.ldi.nrw.de">www.ldi.nrw.de</a>) &uuml;ber die Verarbeitung
          Ihrer personenbezogenen Daten durch uns zu beschweren.
        </p>

        <h3 class="text-subtitle-1 font-weight-bold mb-2">Einzelfallbezogenes Widerspruchsrecht</h3>
        <p>
          Sie haben das Recht, aus Gr&uuml;nden, die sich aus Ihrer besonderen Situation ergeben,
          jederzeit gegen die Verarbeitung Sie betreffender personenbezogener Daten,
          die aufgrund einer Datenverarbeitung im &ouml;ffentlichen Interesse erfolgt,
          Widerspruch einzulegen.
        </p>
        <p>
          Diesen Widerspruch k&ouml;nnen Sie formfrei an das LANUK richten.
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {}
</script>
